import React from "react";
import './ToggleButton.scss';

const ToggleButtonComponent = ({onChangeToggle}: any) => {

  return (
    <div className="toggleButtonContainer">
      <div className="toggleButton">
        <div>
          <label>
            <input type="checkbox" onChange={onChangeToggle}/>
            <span className="base-color">
              <span className="toggle-slider"></span>
              <span className="cash">Monthly</span>
              <span className="token">Yearly</span>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ToggleButtonComponent;
