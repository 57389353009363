import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout/index";
import ToggleButton from "./../components/ToggleButton/index";
import ChoosePlan from "./../components/ChoosePlan/index";
// import CurrencyType from "./../components/CurrencyType/index";
import FeatureComparison from "./../components/FeatureComparison/index";
import favIcon from '../images/FosterTalent_fav_icon.png';
import "./pricings.scss";

const Pricing = () => {
  const [currencyType, setCurrencyType] = useState("usd");
  const [billingType, setBillingType] = useState("monthly");

  const data = useStaticQuery(graphql`
    {
      allContentfulPrice(sort: { fields: createdAt }) {
        edges {
          node {
            planName
            planPrice {
              usd {
                monthly {
                  platformFee
                  userFee
                }
                yearly {
                  platformFee
                  userFee
                }
              }
              inr {
                monthly {
                  platformFee
                  userFee
                }
                yearly {
                  platformFee
                  userFee
                }
              }
              euro {
                monthly {
                  platformFee
                  userFee
                }
                yearly {
                  platformFee
                  userFee
                }
              }
            }
            userFeeTypeLabel
            billedType {
              monthly {
                type
              }
              yearly {
                type
              }
            }
            planFeature {
              content
            }
            choosePlanBtnLabel
          }
        }
      }
      allContentfulPriceFeatureComparison(sort: { fields: createdAt }) {
        edges {
          node {
            header
            featureName
            freemium
            startUp
            growth
            expand
            enterprise
            freemiumText
            startUpText
            growthText
            expandText
          }
        }
      }
    }
  `);

  const onChangeEvent = (e) => {
    setBillingType(e.target.checked ? "yearly" : "monthly");
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Foster Talent</title>
        <link rel="icon" type="image/x-icon" href={favIcon} />
      </Helmet>

      <Layout>
        <div className="PricingContainer">
          <div className="pricing">
            <div className="pricing__header">
              <h3>Choose a plan to find your best fit</h3>
              <p>
                It is a long established fact that a reader will be distracted by
                the readable <br /> content of a page when looking at its layout.
              </p>
            </div>

            <div className="pricing__body">
              {/* <div className="pricing__currency">
                <CurrencyType changeCurrency={setCurrencyType} />
              </div> */}

              <ToggleButton onChangeToggle={onChangeEvent} />

              <div className="pricing__plan-list">
                {data &&
                  data.allContentfulPrice.edges.map((item, index) => (
                    <ChoosePlan
                      item={item.node}
                      key={index}
                      currencyType={currencyType}
                      billingType={billingType}
                    />
                  ))}
              </div>

              <div className="pricing__feature-header">
                <h3>Feature Comparison</h3>

                <div className="pricing__feature-table">
                  <FeatureComparison
                    data={data.allContentfulPriceFeatureComparison.edges}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>

  );
};

export default Pricing;
