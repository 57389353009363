import React, { useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as Icons from "./../../icons";
import "./FeatureComparison.scss";

const FeatureComparisonComponent = ({ data }) => {
  const breakpoints = useBreakpoint();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (breakpoints.sm) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [breakpoints]);

  return (
    <div className="featureComparisonContainer">
      {mobileView ? (
        <div className="featureComparisonMobile">
          <div>
            <table cellSpacing="0">
              <thead>
                <tr>
                  <th><span>Freemium</span></th>
                  <th><span>Start-Up</span></th>
                  <th><span>Growth</span></th>
                  <th><span>Expand</span></th>
                  <th><span>Enterprise</span></th>
                </tr>
              </thead>
              {data &&
                data.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td colSpan="5">{item.node.featureName}</td>
                    </tr>

                    <tr>
                      <td>
                        {item.node.freemium ? (
                          <Icons.CheckIcon />
                        ) : item.node.freemiumText ? (
                          item.node.freemiumText
                        ) : (
                          <Icons.CrossIcon />
                        )}
                      </td>
                      <td>
                        {item.node.startUp ? (
                          <Icons.CheckIcon />
                        ) : item.node.startUpText ? (
                          item.node.startUpText
                        ) : (
                          <Icons.CrossIcon />
                        )}
                      </td>
                      <td>
                        {item.node.growth ? (
                          <Icons.CheckIcon />
                        ) : item.node.growthText ? (
                          item.node.growthText
                        ) : (
                          <Icons.CrossIcon />
                        )}
                      </td>
                      <td>
                        {item.node.expand ? (
                          <Icons.CheckIcon />
                        ) : item.node.expandText ? (
                          item.node.expandText
                        ) : (
                          <Icons.CrossIcon />
                        )}
                      </td>
                      <td>
                        {item.node.enterprise ? (
                          <Icons.CheckIcon />
                        ) : item.node.expandText ? (
                          ""
                        ) : (
                          <Icons.CrossIcon />
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        </div>
      ) : (
        <div className="featureComparison">
          <table cellSpacing="0">
            {data &&
              data.map((item, index) => (
                <tbody key={index}>
                  {item.node.header && index === 0 && (
                    <tr>
                      <th>{item.node.header}</th>
                      <th>Freemium</th>
                      <th>Start-Up</th>
                      <th>Growth</th>
                      <th>Expand</th>
                      <th>Enterprise</th>
                    </tr>
                  )}
                  {item.node.header && index !== 0 && (
                    <tr>
                      <th>{item.node.header}</th>
                      <th colSpan="6"></th>
                    </tr>
                  )}
                  <tr>
                    <td>{item.node.featureName}</td>
                    <td>
                      {item.node.freemium ? (
                        <Icons.CheckIcon />
                      ) : item.node.freemiumText ? (
                        item.node.freemiumText
                      ) : (
                        <Icons.CrossIcon />
                      )}
                    </td>
                    <td>
                      {item.node.startUp ? (
                        <Icons.CheckIcon />
                      ) : item.node.startUpText ? (
                        item.node.startUpText
                      ) : (
                        <Icons.CrossIcon />
                      )}
                    </td>
                    <td>
                      {item.node.growth ? (
                        <Icons.CheckIcon />
                      ) : item.node.growthText ? (
                        item.node.growthText
                      ) : (
                        <Icons.CrossIcon />
                      )}
                    </td>
                    <td>
                      {item.node.expand ? (
                        <Icons.CheckIcon />
                      ) : item.node.expandText ? (
                        item.node.expandText
                      ) : (
                        <Icons.CrossIcon />
                      )}
                    </td>
                    <td>
                      {item.node.enterprise ? (
                        <Icons.CheckIcon />
                      ) : item.node.expandText ? (
                        ""
                      ) : (
                        <Icons.CrossIcon />
                      )}
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      )}
    </div>
  );
};

export default FeatureComparisonComponent;
