import * as React from "react";
import * as Icons from "./../../icons";
import "./ChoosePlan.scss";

const ChoosePlanComponent = ({ item, currencyType, billingType }) => {
  return (
    <div className="choosePlanSection">
      <div className="choosePlan">
        <h4>{item.planName}</h4>
        <h3>{item.planPrice[currencyType][billingType].platformFee}</h3>

        <div className="employeeDetails">
          {item.planPrice[currencyType][billingType].userFee && (
            <p>
              + <b>{item.planPrice[currencyType][billingType].userFee}</b>{" "}
              {item.userFeeTypeLabel}
            </p>
          )}
          {item.billedType && <span>{item.billedType.monthly.type}</span>}
        </div>

        <ul>
          {item.planFeature.map((plan, index) => (
            <li key={index}>
              <Icons.CheckIcon /> {plan.content}
            </li>
          ))}
        </ul>

        <button type="button">{item.choosePlanBtnLabel}</button>
      </div>
    </div>
  );
};

export default ChoosePlanComponent;
